import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function UpcomingEvents() {
  const container = useRef(null);
  const isInView = useInView(container);
  const titleAnimation = useAnimation();
  const cardAnimation = useAnimation();
  const { t } = useTranslation();

  const events = [
    {
      title: "You're Dinomite",
      date: "14 FEB",
      descriptionKey: "youreDinomiteDescription",
    },
    {
      title: "Mardi Gras Carnival",
      date: "8 MAR",
      descriptionKey: "mardiGrasDescription",
    },
    {
      title: "St. Patrick's Day",
      date: "15 MAR",
      descriptionKey: "stPatricksDayDescription",
    },
    {
      title: "April Dogfools",
      date: "5 APR",
      descriptionKey: "aprilDogfoolsDescription",
    },
    {
      title: "Easter Tiny Pawtie",
      date: "18 APR",
      descriptionKey: "easterTinyPawtieDescription",
    },
    {
      title: "Easter Pawtie",
      date: "19 APR",
      descriptionKey: "easterPawtieDescription",
    },
    {
      title: "Cinco de Mayo",
      date: "3 MAY",
      descriptionKey: "cincoDeMayoDescription",
    },
    {
      title: "Mothers Day",
      date: "10 MAY",
      descriptionKey: "mothersDayDescription",
    },
    {
      title: "Welcome Summer",
      date: "31 MAY",
      descriptionKey: "welcomeSummerDescription",
    },
    {
      title: "Luna Vet Help",
      date: "14 JUN",
      descriptionKey: "lunaVetHelpDescription",
    },
    {
      title: "Sato Care Alliance",
      date: "28 JUN",
      descriptionKey: "satoCareAllianceDescription",
    },
    {
      title: "Summer Camp",
      date: "14-18 JUL",
      descriptionKey: "summerCampDescription",
    },
  ];

  useEffect(() => {
    if (isInView) {
      titleAnimation.start({
        y: 0,
        opacity: 1,
        transition: { type: "spring", duration: 1.5, bounce: 0.3 },
      });

      cardAnimation.start({
        scale: 1,
        opacity: 1,
        transition: { type: "spring", duration: 1.2 },
      });
    }
  }, [isInView, titleAnimation, cardAnimation]);

  return (
    <div className="bg-custom-green">
      <div className="mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <motion.h1
          initial={{ y: "-100px", opacity: 0 }}
          animate={titleAnimation}
          className="text-4xl font-bold text-center text-white mb-8"
        >
          {t("upcomingEvent")}
        </motion.h1>
        <div
          ref={container}
          className="grid grid-cols-1 gap-y-16 gap-x-8 md:grid-cols-2 lg:grid-cols-3"
        >
          {events.map((event, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={cardAnimation}
              className="flex items-center gap-6 p-4 bg-white rounded-lg shadow-lg hover:scale-105 transition-transform"
            >
              {/* Date Section */}
              <div
                className="flex-shrink-0 text-center rounded-lg p-4 w-24 h-24 flex flex-col items-center justify-center"
                style={{
                  backgroundColor: "#F9AC8A", // Header color
                }}
              >
                <span className="text-2xl font-bold text-white">
                  {event.date.split(" ")[0]}
                </span>
                <span className="uppercase text-sm text-white">
                  {event.date.split(" ")[1]}
                </span>
              </div>

              {/* Event Info Section */}
              <div>
                <h2 className="text-xl font-bold text-gray-800">{event.title}</h2>
                <p className="text-sm text-gray-600">
                  {t(event.descriptionKey)}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}
