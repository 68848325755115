import { useEffect, useRef} from "react"
import { motion, useInView, useAnimation } from "framer-motion"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export default function Example() {

  const { t } = useTranslation()
  const container = useRef(null)
  const isInView = useInView(container)
  const animation = useAnimation()

  useEffect(() => {
    if(isInView) {
      animation.start({
        x:0,
        transition: {
          type: 'spring', duration: 2, bounce: 0.2
        }
      })
    }
  }, [isInView, animation]);

  return (
    <div>
      <div className="relative bg-custom-rose">
        <div ref={container} className="mx-auto max-w-7xl">
          <motion.div initial={{ x: '-100vw' }} animate={animation} className="relative z-10 lg:w-full lg:max-w-2xl">
            <div className="relative py-32 px-6 sm:py-40 lg:py-56 lg:px-8 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                {t('services')}
                </h1>
                <p className="mt-6 text-lg leading-8 text-white">
                  {t('servicesDescription')}
                </p>
                <div className="mt-10 flex gap-x-6">
                <Link
                    to="/contact"
                    className="rounded-md bg-custom-light-orange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-custom-rose focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                  >
                    {t('contactUs')}
                  </Link>
              </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src="assets/img/dalmata-black.jpg"
            alt="Matilda la dalmata"
          />
        </div>
      </div>
    </div>
  )
}
