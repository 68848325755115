import UpcomingEvents from "../components/UpcomingEvents";



export default function UpcomingEventsPage() {
    return (
        <div>
            <UpcomingEvents />
        </div>
    )
}